
import Vue, { PropType } from 'vue'
import { Block } from '~/domain/interfaces/BlockTypes'
import AppActions from '@constants/AppActions'
import {Tag} from "~/domain/interfaces";

export default Vue.extend({
  name: 'CommonTags',
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
  },

  data() {
    return {
    }
  },
  methods: {
    tagClicked: function (tag: Tag) {
      this.$router.push(`/community/see-all?search=${tag.name}&filter=tags&tagId=${tag.id}`);
    }
  },
})
