var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.block?.title)?_c('div',{staticClass:"d-flex flex-column common-faq-container__parent",class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
    marginTop: _vm.block?.componentStyle?.marginTop + 'px',
    marginBottom: _vm.block?.componentStyle?.marginBottom + 'px',
  })},[_c('div',{staticClass:"common-faq__wrapper"},[_c('h2',{staticClass:"common-faq-container__title",domProps:{"innerHTML":_vm._s(_vm.block?.title)}}),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column common-faq-container"},_vm._l((_vm.block?.faq),function(faq,index){return _c('div',{key:index,staticClass:"title-faq",on:{"mouseover":function($event){return _vm.updateHoverState(index, true)},"mouseleave":function($event){return _vm.updateHoverState(index, false)}}},[_c('div',{staticClass:"glossary-chapter__title-container",style:({
            backgroundColor: _vm.block?.componentStyle?.cardColor,
          })},[_c('div',{staticClass:"glossary-chapter__title"},[_c('div',{ref:"chapterText",refInFor:true,staticClass:"glossary-chapter__title-text common-faq__title-text d-flex align-items-center",class:{ expanded: _vm.faqStates[index] },on:{"click":function($event){return _vm.toggleText(index)}}},[_c('v-icon',{staticClass:"mr-10 faq-icon",class:{
                  'icon-plus': !_vm.faqStates[index],
                  'icon-minus': _vm.faqStates[index],
                },attrs:{"size":"28","color":_vm.hoveringStates[index]
                    ? '#5555f4'
                    : _vm.faqStates[index]
                    ? '#5a55f4'
                    : '#1d1d1d'}},[_vm._v("\n                "+_vm._s(_vm.faqStates[index] ? '$minusIcon' : '$plusIcon'))]),_vm._v(" "),_c('h3',[_vm._v(_vm._s(faq?.title))])],1),_vm._v(" "),(_vm.faqStates[index])?_c('div',{staticClass:"glossary-chapter__content common-faq__content d-flex"},[_c('div',{staticClass:"glossary-chapter__content-container"},[_c('div',{ref:"textContainer",refInFor:true,staticClass:"glossary-chapter__content-text-container"},[_c('p',{ref:"text",refInFor:true,staticClass:"glossary-chapter__content-text common-faq__content-text"},[_vm._v("\n                    "+_vm._s(faq?.extraContent)+"\n                  ")])])])]):_vm._e()])])])}),0)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }